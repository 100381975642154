<template>
    <div class="detail-maxbox">
        <div class="nop_msg">
            <div class="no_info">
                提单号:{{ showNo || $route.query.no }}
            </div>
            <div class="mainSearch">
                <el-input v-model="no" class="main_input" placeholder="查询请输入完整提单号"></el-input>
                <el-button class="searchBtn" type="primary" @click="search">查询</el-button>
            </div>
        </div>
        <el-tabs v-model="activeName" type="card" @tab-click="handleClick" class="tabs">
            <el-tab-pane label="车辆库存" name="second"></el-tab-pane>
            <el-tab-pane label="集装箱" name="first"></el-tab-pane>

            <!--        <el-tab-pane label="整箱危险品" name="third"></el-tab-pane>-->
        </el-tabs>
        <div class="searchBox">
            <div class="carSearch" v-show="activeName == 'second'">
                <div class="vin">
                    <span>VIN码</span> <el-input v-model="carForm.no" class="main_input" placeholder=""></el-input>
                </div>
                <div class="vin">
                    <span>状态</span> <el-select v-model="carForm.status" placeholder="请选择">
                        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </div>
                <el-button class="searchBtn" type="primary" @click="searchCar">查询</el-button>
            </div>
            <div class="boxSearch" v-show="activeName == 'first'">
                <div class="flSearch">
                    <div class="vin">
                        <span>箱号</span> <el-input v-model="boxForm.no" class="main_input" placeholder=""></el-input>
                    </div>
                    <div class="vin">
                        <span>状态</span> <el-select v-model="boxForm.status" placeholder="请选择">
                            <el-option v-for="item in options2" :key="item.value" :label="item.label" :value="item.value">
                            </el-option>
                        </el-select>
                    </div>
                    <el-button class="searchBtn" type="primary" @click="searchBox">查询</el-button>

                </div>
                <div class="otherInfo" v-if="boxList.length">
                    <span> 船名：{{boxList[0].vessel}}</span>
                    <span> 航次：{{boxList[0].voyage_flight}}</span>
                    <span> 目的港：{{boxList[0].pod_info.name_en}}</span>
                </div>
            </div>
        </div>
        <div v-show="activeName == 'second'">
            <el-table :data="carList" v-loading="loading" style="width: 100%" height="528px" highlight-current-row
                header-cell-class-name="table_header">
                <el-table-column prop="vin" label="VIN码" width="180">
                    <template slot-scope="scope">
                        <span style="color: #91be42;cursor: pointer;" @click="linkToDetail(scope.row)"> {{ scope.row.vin
                        }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="name" label="状态" width="80">
                    <template slot-scope="scope">
                        <span v-if="scope.row.status == 1">待入</span>
                        <span v-if="scope.row.status == 2">入库</span>
                        <span v-if="scope.row.status == 3">装箱</span>
                    </template>
                </el-table-column>
                <el-table-column prop="in_wh_time" label="入库时间" width="180">
                    <template slot-scope="scope">
                        {{ scope.row.in_wh_time | secondFormat() }}
                    </template>
                </el-table-column>
                <el-table-column prop="box_no" label="箱号" width="150" />
                <el-table-column prop="brand" label="品牌" width="100" />
                <el-table-column prop="vehicle_model" label="车型" width="100" />
                <el-table-column prop="vehicle_config" label="配置" />
                <el-table-column prop="weight" label="重量（KG）" width="100" />
                <el-table-column prop="vehicle_engine" label="发动机" />
                <el-table-column prop="warehouse_no" label="进仓编号" width="120" />
                <el-table-column prop="address" label="进仓照片 ">
                    <div slot-scope="scope" style="text-align: center;">
                        <span style="color: #91be42;cursor: pointer;" v-if="scope.row.in_wh_image.length"
                            @click="showImgList(scope.row.in_wh_image)"> {{ scope.row.in_wh_image.length }}</span>
                        <span v-else> {{ scope.row.in_wh_image.length }}</span>
                    </div>
                </el-table-column>
            </el-table>
            <div class="block">
                <el-pagination layout="prev, pager, next" :total="car_total" :current-page="car_start" :page-size="limit"
                    @current-change="carPageChange">
                </el-pagination>
            </div>
        </div>
        <div v-show="activeName == 'first'">
            <el-table :data="boxList" style="width: 100%" v-loading="boxLoading" height="528px" highlight-current-row
                header-cell-class-name="table_header">
                <el-table-column prop="date" label="箱号" width="160">
                    <template slot-scope="scope">
                        <span style="color: #91be42;cursor: pointer;" @click="toDetailBox( scope.row )"> {{ scope.row.box_no }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="box_type" label="箱型">
                  
                </el-table-column>
                <el-table-column prop="volume" label="体积" />
                <el-table-column prop="weight" label="重量" />
                <el-table-column prop="tare_weight" label="箱皮重" />
                <el-table-column prop="vgm" label="VGM" />
                <el-table-column prop="vehicle_model" label="车型" />
                <el-table-column prop="vehicle_nums" label="装箱数量" />
                <el-table-column prop="vehicle_config" label="配置" />
                <el-table-column prop="name" label="状态" >
                    <template slot-scope="scope">
                        <span v-if="scope.row.status == 1">待装</span>
                        <span v-if="scope.row.status == 2">操作</span>
                        <span v-if="scope.row.status == 3">已装</span>
                    </template>
                </el-table-column>
                <el-table-column prop="packing_completed" label="完成时间" width="180">
                    <template slot-scope="scope">
                        {{ scope.row.packing_completed | secondFormat() }}
                    </template>
                </el-table-column>
                <el-table-column prop="address" label="装箱照片 " >
                    <div slot-scope="scope" style="text-align: center;">
                        <span style="color: #91be42;cursor: pointer;" v-if="scope.row.packing_image.length"
                            @click="showImgListBox(scope.row.packing_image)"> {{ scope.row.packing_image.length }}</span>
                        <span v-else> {{ scope.row.packing_image.length }}</span>
                    </div>
                </el-table-column>
            </el-table>
            <div class="block">
                <el-pagination layout="prev, pager, next" :total="box_total" :current-page="box_start" :page-size="limit"
                    @current-change="boxPageChange">
                </el-pagination>
            </div>
        </div>
        <el-dialog :title="title" :visible.sync="dialogVisible" width="600px">
            <div class="imgList">
                <el-image class="img" v-for="(item, index) in srcList" :key="index" style="width: 100px; height: 100px"
                    :src="item" fit="cover" :preview-src-list="srcList">
                </el-image>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="downImg" :loading="btnLoading">下载全部照片</el-button>
            </span>
        </el-dialog>
    </div>
</template>
  
<script>
export default {
    name: "detail",

    data() {
        return {
            dialogVisible: false,
            title: '装箱照片',
            loading: false,
            tableData: [],
            activeName: 'second',
            no: null,
            showNo: null,
            carForm: {
                no: '',
                status: 0
            },
            boxForm: {
                no: '',
                status: 0
            },
            options: [
                {
                    value: 0,
                    label: '全部'
                },
                {
                    value: 1,
                    label: '待入'
                },
                {
                    value: 2,
                    label: '入库'
                },
                {
                    value: 3,
                    label: '装箱'
                }
            ],
            options2: [
                {
                    value: 0,
                    label: '全部'
                },
                {
                    value: 1,
                    label: '待装'
                },
                {
                    value: 2,
                    label: '操作'
                },
                {
                    value: 3,
                    label: '已装'
                }
            ],
            car_start: 1,
            box_start: 1,
            box_total: 0,
            car_total: 0,
            limit: 10,
            carList: [],
            srcList: [],
            btnLoading: false,
            boxLoading: false,
            boxList: []
        }
    },
    mounted() {
        this.getCarList()
        this.getBoxList()
    },
    methods: {
        showImgList(item) {
            this.title = '进仓照片'
            this.srcList = item.map(ee => { return ee.url })
            this.dialogVisible = true
        },
        showImgListBox(item){
            this.title = '装箱照片'
            this.srcList = item.map(ee => { return ee.url })
            this.dialogVisible = true
        },
        async downImg() {
            this.btnLoading = true
            let data = await this.$store.dispatch('API_warehouse/downImg', {
                files_url: JSON.stringify(this.srcList),
                download_source: this.title
            })
            window.open(data.data)
            this.btnLoading = false
        },
        search() {
            if (!this.no) {
                this.$message.warning('请输入提单号')
                return
            }
            this.showNo = this.no
            this.car_start = 1
            this.box_start = 1
            this.getCarList()
            this.getBoxList()

          
        },
        toDetailBox(item){
            let url = this.$router.resolve({
                path: "/boxDetail",
                query: {
                    id: item.id,
                }
            });
            window.open(url.href, '_blank');
        },
        linkToDetail(item) {
            let url = this.$router.resolve({
                path: "/carDetail",
                query: {
                    id: item.id,
                }
            });
            window.open(url.href, '_blank');
        },
        async getCarList() {
            this.loading = true
            let parmas = {
                limit: this.limit,
                start: this.car_start - 1,
                bl_no: this.no || this.$route.query.no,
                user_id:this.USER_ID

            }
            if (this.carForm.no) {
                parmas.re_vin = this.carForm.no
            }
            if (this.carForm.status) {
                parmas.status = this.carForm.status
            }
            let res = await this.$store.dispatch("API_warehouse/getCarList", parmas);
            this.loading = false
            if (res.success) {
                this.car_total = res.count
                this.carList = res.data
            }
        },
        async getBoxList() {
            this.boxLoading = true
            let parmas = {
                limit: this.limit,
                start: this.box_start - 1,
                bl_no: this.no || this.$route.query.no,
                user_id:this.USER_ID
            }
            if (this.boxForm.no) {
                parmas.re_box_no = this.boxForm.no
            }
            if (this.boxForm.status) {
                parmas.status = this.boxForm.status
            }
            let res = await this.$store.dispatch("API_warehouse/getBoxList", parmas);
            this.boxLoading = false
            if (res.success) {
                this.box_total = res.count
                this.boxList = res.data
                console.log(this.boxList)
            }
        },
        carPageChange(e) {
            this.car_start = e;
            this.getCarList()
        },
        boxPageChange(e){
            this.box_start = e;
            this.getBoxList()
        },
        searchCar() {

            if (this.carForm.no && this.carForm.no.length < 4) {
                this.$message.warning('VIN码至少输入4位数')
                return
            }
            this.car_start = 1
            this.getCarList()
        },
        searchBox(){
            
            if (this.boxForm.no && this.boxForm.no.length < 4) {
                this.$message.warning('箱号至少输入4位数')
                return
            }
            this.box_start = 1
            this.getBoxList()
        },
        handleClick(index) {

        }
    }
}
</script>
  
<style lang="less" scoped>
.detail-maxbox {
    width: 1300px;
    margin: 0 auto;
}

.tabs {
    padding-top: 32px;

    /deep/ .is-active {
        color: #91be42;
    }

    /deep/ .el-tabs__item:hover {
        color: #91be42;
    }
}

.block {
    text-align: center;
    padding: 20px 0;
}

.nop_msg {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;

    .no_info {
        font-size: 18px;
        color: #333;
        font-weight: 600;
    }

    .mainSearch {
        display: flex;
        align-items: center;

        .main_input {
            width: 300px;
        }
    }
}

/deep/ .el-button--primary {
    background: #91be42 !important;
    border: none;
    margin-left: 20px;
}

.carSearch {
    display: flex;
    align-items: center;

    .vin {
        display: flex;
        align-items: center;
        margin-right: 30px;

        span {
            white-space: nowrap;
            padding-right: 10px;
        }
    }
}

.boxSearch {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .flSearch {
        display: flex;
        align-items: center;

    }

    .vin {
        display: flex;
        align-items: center;
        margin-right: 30px;

        span {
            white-space: nowrap;
            padding-right: 10px;
        }
    }

    .otherInfo {
        span {
            padding-left: 20px;
        }
    }
}

.searchBox {
    margin-bottom: 20px;
}

/deep/ .table_header {
    background: #91be42;

    .cell {
        color: #FFF;
    }
}

/deep/ .cell {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

/deep/ .el-pager {
    .active {
        color: #91be42;
    }

    .number {
        &:hover {
            color: #91be42;
        }
    }
}

.imgList {
    .img {
        margin: 5px 5px;
    }
}
</style>